import { store } from '../utilities'
import { setQueryParams, setTableControls, lockQueue } from '../action_creators'
import makeQueryString from './makeQueryString'
import filterIsSame from './filterIsSame'
import update from './update'

export default function onChange(pagination = { current: 1 }, _filters = {}, sorter = {}) {
  //console.info('onChange - ', pagination, _filters, sorter)
  store.dispatch(lockQueue(true))
  const state = store.getState()
  const ff = {}

  Object.entries(_filters).forEach(([k, v]) => {
    if (v.length > 0) ff[k] = v
  })

  let queryParams = {}
  const filters = { ...state.tableControls.filters, ...ff }
  if (filterIsSame(filters, state.tableControls.filters)) {
    queryParams.from = (pagination.current - 1) * state.query.size || 0
  } else {
    queryParams = makeQueryString(filters)
  }

  sorter = { ...state.tableControls.sorter, ...sorter }

  store.dispatch(setQueryParams(queryParams))
  //console.info('onChange filters:', filters)
  store.dispatch(setTableControls({ pagination, filters, sorter }))

  update()
}
