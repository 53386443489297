import {store} from '../utilities'

export default function makeQuery(tabs = null) {
  const state = store.getState()
  //console.log('state',state)
  const {
    tabs: tabName,
    or = () => [],
    terms = () => null,
    permissions = () => [],
    sorter = () => [],
  } = state.tableVars.tableStrategy || {}
  const p = permissions() || []

  //console.log('p',p)

  const {sorter: sortValues} = state.tableControls
  //console.info('makeQuery Redux tableControls - ', state.tableControls)
  
  const sort = sorter(sortValues) || []

  let ff = []
  let filters = []

  const {
    size,
    from,
    status,
    queryStrings: query_strings = [],
    filters: filtersSaved,
    fullTextQuery: query,
  } = state.query

  // deep merge here?
  if (tabName && status !== `All`) ff.push({term: {[`${tabName}.sortable`]: {value: status}}})
  if (!tabs) {
    
    filters = [...filtersSaved, ...p, ...ff].filter((i) => i)
    //console.log('here ?',filters)
  }

  const qq = {
    type: (state.tableVars.tableStrategy || {}).type,
    query,
    size,
    from,
    sort,
    query_strings,
    filters,
    terms: terms(),
    or: or(),
  }

  if (!qq.type) throw new Error('table has no type')

  //console.log('qq',qq)

  return qq
}
