import {useContext, useEffect} from 'react'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'
import {ReducerContext} from '../reducer'
import {auth} from '../../Auth'

const clients = new DB.Organizations()
const projects = new DB.Projects()

export default function useGetClients() {
  const {state, dispatch} = useContext(ReducerContext)

  const filters = []
  if (auth.sunkaizenUser.type === 'client') {
    filters.push(['id', '==', auth.sunkaizenUser.id])    
  }

  const cc = useDBSubscribe(clients, {filters})
  //console.log('cc',cc)

  useEffect(
    async function () {
      const fff = {...state.filterTypes}
      if ((auth.sunkaizenUser.type === 'scheduler' || auth.sunkaizenUser.type === 'quality-manager')  && !auth.sunkaizenUser?.clientsAll) {
        const arrayOfObjects = Object.entries(auth.sunkaizenUser.clients).map(([id, name]) => ({ id, name }));
        fff.client.options = [...arrayOfObjects]
      }
      else if (auth.sunkaizenUser.type === 'field-user' ) {
        const filters = [
          ['inspection.inspector.id', '==', auth.sunkaizenUser.id]
        ]
        const projectsArray = await projects.get(null, { filters  })
        const associatedClients = []
        projectsArray.data.forEach(element => {
          if(element.client.id && !associatedClients.includes(element.client.id)) {
            associatedClients.push(element.client.id)
          }
        });
        const filteredClients = cc.filter(each => associatedClients.includes(each.id))
        for (let u of filteredClients) {
          fff.client.options.push({
            id: u.id,
            name: u.name,
          }) 
        }
      }
      else {
        for (let u of cc) {
          fff.client.options.push({
            id: u.id,
            name: u.name,
          }) 
        }
      }
      fff.client.options = Array.from(new Set(fff.client.options)).sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })

      //console.log('filter types ',fff)

      dispatch({type: 'filterTypes', value: fff})
    },
    [cc]
  )
}
